<!--实时数据 卡片视图-->
<template>
  <div class="card-container">
    <template v-for="(item,index) of deviceDataFilter">
      <div v-if="item.model =='swLora' || item.model =='dmWater' || item.model == 'dmMaster'" class="item" @click.self="viewDeviceInfo(item)" :key="index">
        <div class="title">
          <span class="iconfont" :class="item.online=='1'? 'icon_online': item.online=='0'?'icon_offline':'icon_sleep'"></span>
          <span class="card_title">{{item.title}}</span><span>({{item.id}})</span>
        </div>

        <div class="data">
          <span>更新时间：{{item.lastAlive}}</span>
          <div class="values">
            <el-tabs class="valu-subs">
              <el-tab-pane v-for="(item2,i) of item.datas" :label="item2|nodeNameFilters(i)" :name="i.toString()" :key='i'>
                <div class="value">
                  <template v-for="(item3,j) of item2">
                    <div class="value-sub" v-if="!item3.hide&&item3.value" :key='j'>
                      <span>{{item3.name}}</span>
                      <span>{{item3.value}}</span>
                    </div>
                  </template>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
      </div>
      <div v-else class="item" @click="viewDeviceInfo(item)" :key="index">
        <div class="title">
          <span class="iconfont" :class="item.online=='1'? 'icon_online': item.online=='0'?'icon_offline':'icon_sleep'"></span>
          <span class="card_title">{{item.title}}</span><span>({{item.id}})</span>
        </div>
        <div class="data">
          <span>更新时间：{{item.lastAlive}}</span>
          <div class="value">
            <template v-for="(item2,i) of item.datas">
              <div class="value-sub" v-if="item2.value" :key='i'>
                <span>{{item2.name}}</span>
                <span>{{item2.value}}</span>
              </div>
            </template>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    deviceDataFilter: {
      type: Array
    }
  },
  watch: {

  },
  filters: {
    nodeNameFilters: function (val, i) {
      if (!val) {
        return '节点'
      }
      let item = val.filter(e => e.key == 'n')
      let num = val.filter(e => e.key == 'i')
      return item[0].name + num[0].value
    }
  },
  data() {
    return {
    }
  },
  created() {
  },
  methods: {
    viewDeviceInfo(item) {
      this.$emit('viewDeviceInfo', item)
    }
  }
}
</script>

<style lang="scss">
$card-w: 290px; //卡片宽度
// 卡片视图
.card-container {
  display: grid;
  color: #606266;
  grid-template-columns: repeat(
    auto-fill,
    minmax($card-w, 1fr)
  ); //minmax(最小宽度，最大宽度)
  grid-row-gap: 20px; //行间距
  grid-column-gap: 20px; //列间距

  .item {
    border: 1px solid $border_color;
    border-radius: 4px;
    box-shadow: 0 4px 8px $border_color;

    &:hover {
      cursor: pointer;
      box-shadow: 0 0 20px 5px darken($color: $border_color, $amount: 5%);
    }

    .title {
      background-color: lighten($color: $border_color, $amount: 3%);
      border-bottom: 1px solid $border_color;
      padding: 10px;
      @include c-hide-text;
      display: flex;
      align-items: center;
      .card_title {
        font-size: 16px;
        font-weight: bold;
      }
      span {
        &:first-child {
          margin-right: 5px;
        }
      }
    }

    .data {
      padding: 15px;

      .values {
        font-size: 17px;
        margin-top: 10px;
        display: grid;
        grid-template-columns: 1fr;
        grid-column-gap: 15%;
        .valu-subs {
          width: 100%;
          overflow-x: auto;
        }
      }
      .value {
        font-size: 15px;
        margin-top: 10px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 15%;

        .value-sub {
          font-size: 16px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          padding: 10px 0;
          border-bottom: 2px dashed darken($color: $border_color, $amount: 10%);

          span {
            padding: 0 1px;

            &:last-child {
              font-weight: bold;
            }

            &:last-child {
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}
</style>